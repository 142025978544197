import React, { useEffect, useState } from "react";
import { LuShare2 } from "react-icons/lu";
import { IoMdCopy } from "react-icons/io";
import { FaRegCheckCircle } from "react-icons/fa";
import { styles } from "./MessageStyle";
import axios from "axios";

function AgentMessage({ msg, setIsShare, setMessageToShare, setMessages }) {
  const [isHoveredShare, setIsHoveredShare] = useState(false);
  const [isHoveredCopy, setIsHoveredCopy] = useState(false);
  const [showNotification, setShowNotification] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(msg.text).then(() => {
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 1000);
    });
  };
  const conversationID = localStorage.getItem("conversationID");

  useEffect(() => {
    if (msg.message === "Connecting...") {
      const timer = setTimeout(async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}chatbot/message/check-agent-reply`,
            {
              conversationId: conversationID,
            }
          );

          if (
            response.data.message ===
              "Agents are busy. Would you like to book an appointment?" ||
            response.data.message ===
              "No user message requesting an agent found."
          ) {
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                message: response.data.message,
              },
            ]);
          }
        } catch (error) {
          console.error("Error checking agent reply:", error);
        }
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [msg, setMessages]);

  return (
    <div
      style={{
        ...styles.messageContainer,
        ...(msg?.sender?.user?.role !== "user"
          ? styles.justifyEnd
          : styles.justifyStart),
      }}
    >
      <p
        style={{
          ...styles.message,
          ...(msg?.sender?.user?.role !== "user"
            ? styles.senderMessage
            : styles.receiverMessage),
        }}
      >
        {msg.message || msg.text}
      </p>

      {msg?.sender?.user?.role === "user" && (
        <div style={styles.icons}>
          <LuShare2
            style={{
              marginBottom: "5px",
              ...(isHoveredShare ? styles.iconHover : {}),
            }}
            onClick={() => {
              setIsShare(true);
              setMessageToShare(msg);
            }}
            onMouseEnter={() => setIsHoveredShare(true)}
            onMouseLeave={() => setIsHoveredShare(false)}
          />
          <IoMdCopy
            style={{
              ...(isHoveredCopy ? styles.iconHover : {}),
            }}
            onMouseEnter={() => setIsHoveredCopy(true)}
            onMouseLeave={() => setIsHoveredCopy(false)}
            onClick={handleCopy}
          />
        </div>
      )}

      {showNotification && (
        <div style={styles.notification}>
          <FaRegCheckCircle style={styles.checkIcon} />
          copied!
        </div>
      )}
    </div>
  );
}

export default AgentMessage;
