import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { notifySuccess, notifyError } from "../../../helpers/Notify";
import swal from "sweetalert";
import axios from "axios";

const Agent = () => {
  const [search, setSearch] = useState("");
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [agents, setAgents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const recordsPerPage = 10;

  const navigate = useNavigate();

  const fetchAgents = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}chatbot/agent/all`
      );
      const agents = response.data.chatAgents.map((agent) => ({
        userId: agent.userId,
        name: agent.name,
        email: agent.email,
        phoneNumber: agent.phoneNumber,
        role: agent.role,
        status: agent.status,
        createdAt: agent.createdAt,
        updatedAt: agent.updatedAt,
        profilePicture: agent.profilePicture,
      }));
      setAgents(agents);
      setFilteredAgents(agents);
    } catch (error) {
      console.error("Error fetching agents:", error);
    }
  };

  useEffect(() => {
    fetchAgents();
  }, []);

  const fetchAgentDetails = async (userId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}chatbot/agent/details/${userId}`
      );
      setSelectedAgent(response.data.chatAgent);
      setShowModal(true);
    } catch (error) {
      console.error("Error fetching agent details:", error);
      notifyError("Failed to fetch agent details");
    }
  };

  const handleUpdate = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_URL}chatbot/agent/update/${selectedAgent.userId}`,
        selectedAgent
      );
      setShowModal(false);
      fetchAgents();
      notifySuccess("Agent updated successfully");
    } catch (error) {
      console.error("Error updating agent:", error);
      notifyError("Failed to update agent");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedAgent((prev) => ({ ...prev, [name]: value }));
  };

  const handleDelete = (agent) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this agent!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteAgent(agent.userId);
      } else {
        swal("The agent is safe!");
      }
    });
  };

  const deleteAgent = async (userId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}chatbot/agent/delete/${userId}`
      );
      const updatedAgents = filteredAgents.filter(
        (agent) => agent.userId !== userId
      );
      setFilteredAgents(updatedAgents);
      notifySuccess("Agent deleted successfully");
    } catch (error) {
      console.error("Error deleting agent:", error);
      notifyError("Failed to delete agent");
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    const filtered = agents.filter((agent) =>
      agent.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredAgents(filtered);
  };

  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = filteredAgents.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filteredAgents.length / recordsPerPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="tbl-caption p-2 d-flex justify-content-between align-items-center">
                <h4 className="heading mb-0">Agent Management</h4>

                <div className="d-flex">
                  <input
                    type="text"
                    value={search}
                    onChange={handleSearch}
                    placeholder="Search by name"
                    className="form-control"
                    style={{ width: "200px", marginRight: "2px" }}
                  />{" "}
                  <button
                    onClick={() => navigate("/admin/agent/create")}
                    className="btn btn-primary btn-sm"
                    style={{ whiteSpace: "nowrap", padding: "0.7rem 1.5rem" }}
                  >
                    <i className="fa-solid fa-plus me-2"></i>Add Agent
                  </button>
                </div>
              </div>

              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Profile</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Role</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {records.map((agent, index) => (
                    <tr key={agent.userId}>
                      <td>{firstIndex + index + 1}</td>
                      <td>
                        <img
                          src={
                            agent.profilePicture ||
                            "https://as1.ftcdn.net/v2/jpg/03/46/83/96/1000_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg"
                          }
                          alt="Profile"
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: "50%",
                          }}
                        />
                      </td>
                      <td>{agent.name}</td>
                      <td>{agent.email}</td>
                      <td>{agent.phoneNumber}</td>
                      <td style={{ textTransform: "capitalize" }}>
                        {agent.role}
                      </td>
                      <td style={{ textTransform: "capitalize" }}>
                        {agent.status}
                      </td>
                      <td>
                        <button
                          onClick={() => fetchAgentDetails(agent.userId)}
                          className="btn btn-warning btn-sm me-2"
                          disabled={agent.role === "bot" ? true : false}
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDelete(agent)}
                          className="btn btn-danger btn-sm"
                          disabled={agent.role === "bot" ? true : false}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {showModal && selectedAgent && (
        <div className="modal show d-block" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Agent</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="mb-3">
                    <label>Name</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      value={selectedAgent.name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      value={selectedAgent.email}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label>Phone</label>
                    <input
                      type="text"
                      name="phoneNumber"
                      className="form-control"
                      value={selectedAgent.phoneNumber}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label>Status</label>
                    <select
                      name="status"
                      className="form-select"
                      value={selectedAgent.status}
                      onChange={handleInputChange}
                    >
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </select>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleUpdate}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Agent;
