import axios from "axios";
import React, { useState } from "react";

const styles = {
  container: {
    width: "100%",
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  button: {
    background: "#cb0e7b",
    border: "none",
    outline: "none",
    padding: "2px 12px",
    fontFamily: "Outfit",
    borderRadius: "7px",
    color: "#fff",
    boxShadow: "0 0 9px 1px rgba(0,0,0,0.1)",
    marginRight: "7px",
    transition: "background-color 0.1s ease",
  },
  buttonYes: {
    background: "#197bb3",
    border: "none",
    outline: "none",
    padding: "2px 12px",
    fontFamily: "Outfit",
    borderRadius: "10px",
    transition: "background-color 0.1s ease",
    color: "#fff",
  },
};

function Buttons({ msg, setMessages, setLoading, loading }) {
  const [isShow, setIsShow] = useState(true);
  const conversationID = localStorage.getItem("conversationID");
  const chatUser = JSON.parse(localStorage.getItem("chatUser"));

  localStorage.setItem("lastMessage", msg.lastMessage);

  const handleSubmit = async (action) => {
    setLoading(false);
    setIsShow(false);
    try {
      const lastMessage = localStorage.getItem("lastMessage");
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}chatbot/message/agent-confirmation`,
        {
          conversationId: conversationID,
          sender: chatUser?.newSession?.sessionId,
          text: lastMessage,
          isAgent: action,
        }
      );
      const replyMessage = {
        message: response?.data?.data?.reply || response?.data?.reply,
      };
      setMessages((prevMessages) => [...prevMessages, replyMessage]);

      setLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    !loading && (
      <div>
        <p>{msg.text}</p>
        {isShow &&
          (msg.message !==
          "Agents are busy. Would you like to book an appointment?" ? (
            <div style={styles.container}>
              <button
                style={styles.button}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "#a10c6c")
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "#cb0e7b")
                }
                onClick={() => handleSubmit(false, msg.message)}
              >
                No
              </button>
              <button
                style={styles.buttonYes}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "#197bb3")
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "dodgerblue")
                }
                onClick={() => handleSubmit(true, msg.message)}
              >
                Yes
              </button>
            </div>
          ) : (
            ""
          ))}
      </div>
    )
  );
}

export default Buttons;
