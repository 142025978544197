import React, { useEffect, useRef, useState } from "react";
import { BsSendFill } from "react-icons/bs";
import axios from "axios";
import Drawer from "../chat/parts/Chat/Drawer";
import { styles } from "./style";
import WelcomeMessage from "./WelcomeMessage";
import ChatSidebar from "./ChatSidebar";
import Loader from "../chat/parts/Chat/Loader";
import { dateFormat } from "../../../helpers/DateFormat";
import { io } from "socket.io-client";
import AgentMessage from "../chat/parts/Chat/AgentMessage";
import { RiUserAddLine } from "react-icons/ri";
import { AiOutlineDelete } from "react-icons/ai";
import { PiPhoneCallLight } from "react-icons/pi";
import { GoPaste } from "react-icons/go";
import "./style.css";
import UserProfile from "./UserProfile";
// const socket = io("http://localhost:8080");

const socket = io("https://api.beyondaio.com");

function Conversations() {
  const [activeConversation, setActiveConversation] = useState(null);
  const [inputMessage, setInputMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const [messageToShare, setMessageToShare] = useState("");
  const [isProfile, setIsProfile] = useState(false);
  const [summary, setSummary] = useState(null);
  const [activeTab, setActiveTab] = useState("details");

  useEffect(() => {
    socket.emit("adminJoin");

    if (activeConversation) {
      socket.emit("joinConversation", activeConversation?.conversationId);
    }

    socket.on("newMessage", (newMessage) => {
      console.log("new message socket");
      console.log(newMessage);
      if (newMessage.conversationId === activeConversation?.conversationId) {
        setMessages((prevMessages) => [...(prevMessages || []), newMessage]);
      }
    });

    return () => {
      socket.off("newMessage");
    };
  }, [activeConversation]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputMessage.trim() === "") return;
    await sendMessage(inputMessage);
  };

  const sendMessage = async (messageText) => {
    setInputMessage("");
    setLoading(true);
    setIsInputDisabled(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}chatbot/reply-by-agent`,
        {
          conversationId: activeConversation?.conversationId,
          text: messageText,
          receiver: "Bot",
        }
      );

      socket.emit("sendMessage", {
        conversationId: activeConversation?.conversationId,
        message: messageText,
        role: "bot",
      });
    } catch (error) {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: messages.length + 2,
          receiver: "Bot",
          message: "Error processing your request.",
        },
      ]);
    } finally {
      setLoading(false);
      setIsInputDisabled(false);
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    if (activeConversation) {
      fetchConversationMessages();
    }
  }, [activeConversation]);

  const fetchConversationMessages = async () => {
    const activeConversationID = activeConversation?.conversationId;
    if (activeConversationID) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}chatbot/message/conversations/${activeConversationID}/messages?admin=yes`
        );

        setMessages(response.data.messages);
        setSummary(response.data.summary);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    }
  };
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  return (
    <div style={styles.container}>
      <ChatSidebar
        activeConversation={activeConversation}
        setActiveConversation={setActiveConversation}
        from="panel"
        setIsProfile={setIsProfile}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />

      <div style={styles.mainChat}>
        {activeConversation ? (
          <>
            <div style={styles.header}>
              <div style={styles.left} onClick={() => setIsProfile(!isProfile)}>
                <img
                  src={activeConversation?.members[0]?.user?.profilePicture}
                  alt="Avatar"
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    marginRight: "10px",
                  }}
                />
                <div style={{ flexGrow: 1 }}>
                  <h6 style={{ margin: "0" }}>
                    {activeConversation?.members[0]?.user?.name}
                  </h6>
                  <small style={{ color: "#888" }}>
                    {dateFormat(activeConversation?.createdAt)}
                  </small>
                </div>
              </div>
              <div style={styles.right}>
                <AiOutlineDelete className="_icon" />
                <PiPhoneCallLight className="_icon" />
                <GoPaste className="_icon" />
                <RiUserAddLine className="_icon" />
              </div>
            </div>
            <div style={styles.messagesContainer}>
              {messages?.length > 0 ? (
                messages.map((msg) => (
                  <AgentMessage
                    msg={msg}
                    key={msg.id}
                    setIsShare={setIsShare}
                    setMessageToShare={setMessageToShare}
                  />
                ))
              ) : (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  No messages yet
                </div>
              )}
              {loading && <Loader />}
              <div ref={messagesEndRef} />
            </div>
            <form onSubmit={handleSubmit} style={styles.form}>
              <div style={styles.inputWrapper}>
                <input
                  type="text"
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                  placeholder="Ask me anything..."
                  style={styles.input(isInputDisabled)}
                  disabled={isInputDisabled}
                  ref={inputRef}
                />
                <button type="submit" style={styles.button}>
                  <BsSendFill size={18} />
                </button>
              </div>
            </form>
          </>
        ) : (
          <WelcomeMessage />
        )}

        {isProfile && (
          <UserProfile
            activeConversation={activeConversation}
            summary={summary}
            setIsProfile={setIsProfile}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}

        {isShare && (
          <Drawer
            isShare={isShare}
            setIsShare={setIsShare}
            messageToShare={messageToShare.message || messageToShare.text}
          />
        )}
      </div>
    </div>
  );
}

export default Conversations;
