import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { notifySuccess, notifyError } from "../../../helpers/Notify";
import swal from "sweetalert";
import { CSVLink } from "react-csv";
import axios from "axios";

const Configuration = () => {
  const [search, setSearch] = useState("");
  const [filteredConfigurations, setFilteredConfigurations] = useState([]);
  const [configurations, setConfigurations] = useState([]);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 13;

  // Fetch configurations from the API
  const fetchConfigurations = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}chatbot/configuration`
      );

      const data = await response.json();
      const configurations = data.configuration.configurations.map(
        (config) => ({
          id: config.id,
          businessName: config.businessName,
          industry: config.businessType,
          location: config.location,
          operatingHours: config.operatingHours,
          supportHours: config.supportHours,
        })
      );
      setConfigurations(configurations);
      setFilteredConfigurations(configurations);
    } catch (error) {
      console.error("Error fetching configurations:", error);
    }
  };

  useEffect(() => {
    fetchConfigurations();
  }, []);

  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = filteredConfigurations.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filteredConfigurations.length / recordsPerPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    const filtered = configurations.filter((config) =>
      config.businessName.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredConfigurations(filtered);
  };

  const handleDelete = (config) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this configuration!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteConfiguration(config.id);
      } else {
        swal("Your configuration is safe!");
      }
    });
  };

  const deleteConfiguration = async (configId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}chatbot/configuration/delete/${configId}`
      );
      console.log(response.data.message);

      const updatedConfigurations = filteredConfigurations.filter(
        (c) => c.id !== configId
      );
      setFilteredConfigurations(updatedConfigurations);

      await fetchConfigurations();

      notifySuccess("Configuration deleted successfully");
    } catch (error) {
      console.error("Error deleting configuration:", error);
      notifyError("Failed to delete configuration");
    }
  };

  const handleEdit = (config) =>
    navigate(`/admin/configuration/create?id=` + config.id);
  const handleView = (config) =>
    navigate(`/admin/configuration/view?id=` + config.id);

  const headersTitle = [
    { label: "#", key: "index" },
    { label: "Business Name", key: "businessName" },
    { label: "Industry", key: "industry" },
    { label: "Location", key: "location" },
    { label: "Operating Hours", key: "operatingHours" },
    { label: "Support Hours", key: "supportHours" },
  ];

  const csvlink = {
    headers: headersTitle,
    data: filteredConfigurations.map((config, index) => ({
      index: index + 1,
      businessName: config.businessName,
      industry: config.industry,
      location: config.location,
      operatingHours: config.operatingHours,
      supportHours: config.supportHours,
    })),
    filename: "configurations.csv",
  };

  const changeCPage = (n) => setCurrentPage(n);
  const prePage = () => setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
  const nextPage = () =>
    setCurrentPage((prev) => (prev < npage ? prev + 1 : prev));

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive active-projects task-table">
                <div className="tbl-caption d-flex justify-content-between align-items-center">
                  <h4 className="heading mb-0">Configuration Management</h4>
                  <div className="d-flex align-items-center">
                    {filteredConfigurations.length > 0 && (
                      <CSVLink
                        {...csvlink}
                        className="btn btn-primary light btn-sm me-2"
                        style={{
                          whiteSpace: "nowrap",
                          padding: "0.7rem 1.5rem",
                        }}
                      >
                        <i className="fa-solid fa-file-excel me-2" />
                        Export Report
                      </CSVLink>
                    )}
                    <input
                      type="text"
                      name="search"
                      value={search}
                      onChange={handleSearch}
                      placeholder="Search"
                      className="form-control me-2"
                    />
                    {configurations?.length === 0 && (
                      <Link
                        to="/admin/configuration/create"
                        className="btn btn-primary btn-sm"
                        style={{
                          whiteSpace: "nowrap",
                          padding: "0.7rem 1.5rem",
                        }}
                      >
                        <i className="fa-solid fa-plus me-2"></i>
                        Add Configuration
                      </Link>
                    )}
                  </div>
                </div>

                <table className="table ItemsCheckboxSec dataTable no-footer mb-2">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Business Name</th>
                      <th>Industry</th>
                      <th>Location</th>
                      <th>Operating Hours</th>
                      <th>Support Hours</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {records.map((config, index) => (
                      <tr key={index}>
                        <td>{firstIndex + index + 1}</td>
                        <td>{config.businessName}</td>
                        <td>{config.industry}</td>
                        <td>{config.location}</td>
                        <td>{config.operatingHours}</td>
                        <td>{config.supportHours}</td>
                        <td>
                          <span
                            onClick={() => handleView(config)}
                            style={{
                              marginRight: "8px",
                              color: "blue",
                              cursor: "pointer",
                            }}
                          >
                            <i className="fas fa-eye" title="View"></i>
                          </span>
                          <span
                            onClick={() => handleEdit(config)}
                            style={{
                              marginRight: "8px",
                              color: "green",
                              cursor: "pointer",
                            }}
                          >
                            <i className="fas fa-edit" title="Edit"></i>
                          </span>
                          <span
                            onClick={() => handleDelete(config)}
                            style={{ color: "red", cursor: "pointer" }}
                          >
                            <i className="fas fa-trash" title="Delete"></i>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="d-flex justify-content-between align-items-center py-1 px-3">
                  <div>
                    Showing {firstIndex + 1} to{" "}
                    {lastIndex > filteredConfigurations.length
                      ? filteredConfigurations.length
                      : lastIndex}{" "}
                    of {filteredConfigurations.length} entries
                  </div>
                  <div className="pagination">
                    <Link
                      to="#"
                      onClick={prePage}
                      className={currentPage === 1 ? "disabled" : ""}
                    >
                      <i className="fa-solid fa-angle-left" />
                    </Link>
                    {number.map((n) => (
                      <Link
                        key={n}
                        to="#"
                        className={currentPage === n ? "current" : ""}
                        onClick={() => changeCPage(n)}
                      >
                        {n}
                      </Link>
                    ))}
                    <Link
                      to="#"
                      onClick={nextPage}
                      className={currentPage === npage ? "disabled" : ""}
                    >
                      <i className="fa-solid fa-angle-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Configuration;
