import React, { useState, useEffect } from "react";
import { notifySuccess, notifyError } from "../../../helpers/Notify";
import axios from "axios";
import { CSVLink } from "react-csv";
import swal from "sweetalert";
import { Link } from "react-router-dom";

const Appointment = () => {
  const [search, setSearch] = useState("");
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 13;
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  // Fetch appointments from the API
  const fetchAppointments = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}chat-appointment`
      );
      setAppointments(response.data.appointments);
      setFilteredAppointments(response.data.appointments);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  useEffect(() => {
    fetchAppointments();
  }, []);

  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = filteredAppointments.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filteredAppointments.length / recordsPerPage);
  const number = [...Array(npage + 1).keys()].slice(1);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
    const filtered = appointments.filter((appointment) =>
      appointment?.chatUser?.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredAppointments(filtered);
  };

  const handleDelete = (appointment) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this appointment!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteAppointment(appointment.appointmentId);
      } else {
        swal("Your appointment is safe!");
      }
    });
  };

  const deleteAppointment = async (appointmentId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}api/chat-appointment/delete/${appointmentId}`
      );
      console.log(response.data.message);

      const updatedAppointments = filteredAppointments.filter(
        (a) => a.appointmentId !== appointmentId
      );
      setFilteredAppointments(updatedAppointments);

      await fetchAppointments();

      notifySuccess("Appointment deleted successfully");
    } catch (error) {
      console.error("Error deleting appointment:", error);
      notifyError("Failed to delete appointment");
    }
  };

  const updateStatus = async (appointmentId, newStatus) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}chat-appointment/update/${appointmentId}`,
        { status: newStatus }
      );
      console.log(response.data.message);

      // Update the status in the local state
      const updatedAppointments = filteredAppointments.map((appointment) =>
        appointment.appointmentId === appointmentId
          ? { ...appointment, status: newStatus }
          : appointment
      );
      setFilteredAppointments(updatedAppointments);

      notifySuccess(`Status updated to ${newStatus}`);
    } catch (error) {
      console.error("Error updating status:", error);
      notifyError("Failed to update status");
    }
  };

  const headersTitle = [
    { label: "#", key: "index" },
    { label: "User Name", key: "userName" },
    { label: "Date", key: "date" },
    { label: "Time", key: "time" },
    { label: "Phone Number", key: "phoneNumber" },
    { label: "Status", key: "status" },
  ];

  const csvlink = {
    headers: headersTitle,
    data: filteredAppointments.map((appointment, index) => ({
      index: index + 1,
      userName: appointment?.chatUser?.name,
      date: appointment.date,
      time: appointment.time,
      phoneNumber: appointment.chatUser?.phoneNumber,
      status: appointment.status,
    })),
    filename: "appointments.csv",
  };

  const changeCPage = (n) => setCurrentPage(n);
  const prePage = () => setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
  const nextPage = () =>
    setCurrentPage((prev) => (prev < npage ? prev + 1 : prev));

  // Function to open appointment details in custom modal
  const showAppointmentDetails = (appointment) => {
    setSelectedAppointment(appointment);
    setModalVisible(true);
  };

  // Function to determine status color
  const getStatusColor = (status) => {
    switch (status) {
      case "open":
        return "green";
      case "closed":
        return "red";
      case "pending":
        return "orange";
      default:
        return "gray";
    }
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedAppointment(null);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive active-projects task-table">
                <div className="tbl-caption d-flex justify-content-between align-items-center">
                  <h4 className="heading mb-0">Appointment Management</h4>
                  <div className="d-flex align-items-center">
                    {filteredAppointments.length > 0 && (
                      <CSVLink
                        {...csvlink}
                        className="btn btn-primary light btn-sm me-2"
                        style={{
                          whiteSpace: "nowrap",
                          padding: "0.7rem 1.5rem",
                        }}
                      >
                        <i className="fa-solid fa-file-excel me-2" />
                        Export Report
                      </CSVLink>
                    )}
                    <input
                      type="text"
                      name="search"
                      value={search}
                      onChange={handleSearch}
                      placeholder="Search"
                      className="form-control me-2"
                    />
                  </div>
                </div>

                <table className="table ItemsCheckboxSec dataTable no-footer mb-2">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>User Name</th>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Phone Number</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {records.map((appointment, index) => (
                      <tr key={index}>
                        <td>{firstIndex + index + 1}</td>
                        <td>
                          <div className="d-flex align-items-center">
                            <img
                              src={appointment.chatUser.profilePicture}
                              alt="User Profile"
                              style={{
                                width: 40,
                                height: 40,
                                borderRadius: "50%",
                                marginRight: "12px",
                              }}
                            />
                            {appointment?.chatUser?.name}
                          </div>
                        </td>
                        <td>{appointment.date}</td>
                        <td>{appointment.time}</td>
                        <td>{appointment.chatUser?.phoneNumber}</td>
                        <td>
                          <span
                            style={{
                              color: getStatusColor(appointment.status),
                              fontWeight: "bold",
                            }}
                          >
                            {appointment.status}
                          </span>
                        </td>
                        <td>
                          <span
                            onClick={() => showAppointmentDetails(appointment)}
                            style={{
                              marginRight: "12px",
                              color: "blue",
                              cursor: "pointer",
                              fontSize: "18px",
                            }}
                          >
                            <i
                              className="fa-solid fa-eye"
                              title="View Details"
                            ></i>
                          </span>
                          <span
                            onClick={() =>
                              updateStatus(appointment.appointmentId, "open")
                            }
                            style={{
                              marginRight: "12px",
                              color: "green",
                              cursor: "pointer",
                              fontSize: "18px",
                            }}
                          >
                            <i className="fa-solid fa-check" title="Accept"></i>
                          </span>
                          <span
                            onClick={() =>
                              updateStatus(appointment.appointmentId, "closed")
                            }
                            style={{
                              marginRight: "12px",
                              color: "red",
                              cursor: "pointer",
                              fontSize: "18px",
                            }}
                          >
                            <i className="fa-solid fa-times" title="Reject"></i>
                          </span>
                          <span
                            onClick={() => handleDelete(appointment)}
                            style={{
                              color: "red",
                              cursor: "pointer",
                              fontSize: "18px",
                            }}
                          >
                            <i className="fas fa-trash" title="Delete"></i>
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="d-flex justify-content-between align-items-center py-1 px-3">
                  <div>
                    Showing {firstIndex + 1} to{" "}
                    {lastIndex > filteredAppointments.length
                      ? filteredAppointments.length
                      : lastIndex}{" "}
                    of {filteredAppointments.length} entries
                  </div>
                  <div className="pagination">
                    <Link
                      to="#"
                      onClick={prePage}
                      className={currentPage === 1 ? "disabled" : ""}
                    >
                      <i className="fa-solid fa-angle-left" />
                    </Link>
                    {number.map((n) => (
                      <Link
                        key={n}
                        to="#"
                        className={currentPage === n ? "current" : ""}
                        onClick={() => changeCPage(n)}
                      >
                        {n}
                      </Link>
                    ))}
                    <Link
                      to="#"
                      onClick={nextPage}
                      className={currentPage === npage ? "disabled" : ""}
                    >
                      <i className="fa-solid fa-angle-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Custom Modal for appointment details */}
      {modalVisible && selectedAppointment && (
        <div
          className="modal fade show"
          style={{ display: "block" }}
          tabIndex="-1"
        >
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Appointment Details</h5>
              </div>

              <div className="modal-body">
                <img
                  src={selectedAppointment.chatUser.profilePicture}
                  style={{
                    height: "120px",
                    width: "120px",
                    borderRadius: "50%",
                    marginBottom: "30px",
                  }}
                  alt=""
                />
                <p>
                  <strong>User Name:</strong>{" "}
                  {selectedAppointment?.chatUser?.name}
                </p>
                <p>
                  <strong>Date:</strong> {selectedAppointment.date}
                </p>
                <p>
                  <strong>Time:</strong> {selectedAppointment.time}
                </p>
                <p>
                  <strong>Phone Number:</strong>{" "}
                  {selectedAppointment.chatUser?.phoneNumber}
                </p>
                <p>
                  <strong>Status:</strong> {selectedAppointment.status}
                </p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Appointment;
