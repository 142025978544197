import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

function CreateAgent() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    status: "inactive",
    role: "agent",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `${field
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase())} is required`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}chatbot/agent`,
        formData
      );
      navigate("/admin/agents");
    } catch (error) {
      swal({
        title: "Error",
        text: error?.response?.data?.message || error.message,
        icon: "error",
        buttons: true,
        dangerMode: true,
      });
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive active-projects task-table">
                <div className="tbl-caption d-flex justify-content-between align-items-center">
                  <h4 className="heading mb-0">Create Agent</h4>
                </div>

                <form onSubmit={handleSubmit} className="p-3">
                  <div className="row">
                    {Object.keys(formData).map((field, index) => (
                      <div key={index} className="col-md-6 mb-3">
                        <label htmlFor={field} style={{ fontWeight: "bold" }}>
                          {field
                            .replace(/([A-Z])/g, " $1")
                            .replace(/^./, (str) => str.toUpperCase())}
                          :
                        </label>
                        {field === "status" ? (
                          <select
                            id={field}
                            name={field}
                            value={formData[field]}
                            onChange={handleChange}
                            className="form-select"
                          >
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                        ) : field === "role" ? (
                          <select
                            id={field}
                            name={field}
                            value={formData[field]}
                            onChange={handleChange}
                            className="form-select"
                          >
                            <option value="agent">Agent</option>
                            <option value="bot">Bot</option>
                          </select>
                        ) : (
                          <input
                            type={field === "email" ? "email" : "text"}
                            id={field}
                            name={field}
                            value={formData[field]}
                            onChange={handleChange}
                            className="form-control"
                            placeholder={`Enter ${field
                              .replace(/([A-Z])/g, " $1")
                              .toLowerCase()}`}
                          />
                        )}
                        {errors[field] && (
                          <div className="text-danger">{errors[field]}</div>
                        )}
                      </div>
                    ))}
                  </div>

                  <button type="submit" className="btn btn-primary mt-3">
                    Create Agent
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateAgent;
