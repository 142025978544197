import React, { useEffect, useState } from "react";
import { FcCalendar } from "react-icons/fc";

const IntegrationComponent = () => {
  const [integratedUser, setIntegratedUser] = useState(
    JSON.parse(localStorage.getItem("integratedUser")) || null
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const user = urlParams.get("user");

    const accessToken = urlParams.get("accessToken");

    if (user && accessToken) {
      const parsedUser = JSON.parse(user);
      localStorage.setItem("integratedUser", JSON.stringify(parsedUser));
      setIntegratedUser(parsedUser);
      localStorage.setItem("accessToken", accessToken);

      window.history.replaceState(null, "", window.location.pathname);
    }
  }, []);

  const handleConnectClick = () => {
    window.location.href = `${process.env.REACT_APP_SOCKET_URL}/auth/google`;
  };

  const hanldeDisConnect = () => {
    localStorage.removeItem("integratedUser");
    setIntegratedUser(null);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-4">
              <h3>Calendars</h3>
              <p style={{ color: "black", fontWeight: "500" }}>
                Connect your Google calendars for booking meetings.
              </p>
              <div className="d-flex justify-content-between">
                <div className="border w-50 rounded-2 p-3">
                  <div className="d-flex justify-content-between border-bottom pb-3">
                    <h3 className="d-flex align-items-center">
                      <span style={{ marginRight: "3px" }}>
                        Google Calendar
                      </span>
                      <FcCalendar />
                    </h3>
                    {integratedUser ? (
                      <button
                        className="bg-success border-0 select-none outline-0 text-white py-1 px-3 rounded shadow cursor-pointer"
                        disabled
                      >
                        Connected
                      </button>
                    ) : (
                      <button
                        className="bg-black border-0 select-none outline-0 text-white py-1 px-3 rounded shadow cursor-pointer hover:bg-danger"
                        onClick={handleConnectClick}
                      >
                        Connect
                      </button>
                    )}
                  </div>
                  <div>
                    <p className="my-2" style={{ fontWeight: "500" }}>
                      Connected Accounts
                    </p>
                    <div className="w-100 d-flex justify-content-between align-items-end ">
                      {integratedUser ? (
                        <p style={{ fontWeight: "bold" }}>
                          {integratedUser.email}
                        </p>
                      ) : (
                        <p className="my-2">
                          There is no account connected till now.
                        </p>
                      )}

                      {integratedUser && (
                        <p
                          className="text-black cursor-pointer hover:cursor-pointer"
                          style={{
                            fontWeight: "500",
                            border: "1px solid #ccc",
                            padding: "1px 10px",
                            borderRadius: "20px",
                          }}
                          onClick={hanldeDisConnect}
                        >
                          Disconnect
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntegrationComponent;
