const styles = {
  container: {
    background:
      "linear-gradient(to bottom, rgba(45, 255, 204, 0.6), rgba(0, 128, 128, 0.1))",
    height: "100%",
    borderRadius: "15px",
  },
  header: {
    fontSize: "50px",
    width: "90%",
  },
  card: {
    width: "100%",
    maxWidth: "400px",
    borderRadius: "16px",
    margin: "5px 0",
  },
  img: {
    width: "50px",
    height: "50px",
  },
  title: {
    fontSize: "24px",
  },
  textMuted: {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: 1.5,
  },
  inputgrp: {
    display: "flex",
    alignItems: "center",
    padding: "0.6rem 1rem",
    borderRadius: "25px",
    margin: "0.5rem 0",
    backgroundColor: "#f3f3f3",
    border: "2px solid",
    transition: "border-color 0.3s ease",
  },
  input: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    color: "#333",
    fontSize: "16px",
    width: "100%",
  },
  button: {
    borderRadius: "30px",
    transition: "background-color 0.3s ease, transform 0.3s ease",
  },
};

export default styles;
