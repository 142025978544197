import React, { useState, useEffect } from "react";
import WelcomePage from "../../screens/WelcomePage/WelcomePage";
import ChatPage from "../../screens/ChatPage/ChatPage";
import AllChats from "../../screens/AllChats/AllChats";

const Chat = () => {
  const [page, setPage] = useState(0);

  useEffect(() => {
    const user = localStorage.getItem("chatUser");
    if (user) {
      setPage(2);
    }
  }, []);

  const nextPage = () => setPage((prevPage) => Math.min(prevPage + 1, 2));
  const prevPage = () => setPage((prevPage) => Math.max(prevPage - 1, 0));

  const renderPage = () => {
    switch (page) {
      case 0:
        return <WelcomePage nextPage={nextPage} />;
      case 1:
        return <ChatPage nextPage={nextPage} prevPage={prevPage} />;
      case 2:
        return <AllChats prevPage={prevPage} />;
      default:
        return null;
    }
  };

  return <div className="h-100">{renderPage()}</div>;
};

export default Chat;
