export const styles = {
  container: {
    display: "flex",
    height: "84vh",
    width: "98%",
    background: "#fff",
    borderRadius: "5px",
    overflow: "hidden",
    margin: "auto",
    fontFamily: "Plus Jakarta Sans",
  },
  sidebar: {
    width: "20%",
    background: "#fff",
    borderRight: ".5px solid #E9E9E9",
    display: "flex",
    flexDirection: "column",
  },
  mainChat: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  header: {
    boxShadow: "0 0 9px 1px rgba(0,0,0,0.1)",
    padding: "1rem",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  left: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  right: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "22px",
  },
  messagesContainer: {
    flex: 1,
    padding: "10px 0 10px 0.5rem",
    overflowY: "auto",
    scrollbarWidth: "none",
    padding: "1rem",
  },
  form: {
    display: "flex",
    alignItems: "center",
    borderTop: "1px solid #ccc",
    padding: "1rem 0",
    background: "#fff",
  },
  inputWrapper: {
    display: "flex",
    flexGrow: 1,
    marginRight: "1rem",
    padding: "1rem",
  },
  input: (isDisabled) => ({
    background: "#f5f5f5",
    borderRadius: "8px",
    width: "100%",
    padding: "1rem",
    border: "1px solid transparent",
    outline: "none",
    color: isDisabled ? "#999" : "#333",
    backgroundColor: !isDisabled ? "#C1FFF0" : "#f5f5f5",
    fontWeight: "500",
  }),
  button: {
    height: "100%",
    padding: "18px",
    background: "#2dffcc",
    cursor: "pointer",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    border: "none",
    marginLeft: "10px",
  },
  listItem: (isActive) => ({
    background: isActive ? "#1B997E1A" : "rgba(239, 240, 242,0.4) ",
    color: isActive ? "#fff" : "#000",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    padding: "10px",
    margin: "9px 10px",
    borderRadius: "5px",
    border: isActive ? "1px solid #2DFFCC" : "1px solid transparent",
    position: "relative",
  }),
};
