import React, { useState } from "react";
import "./style.css";
import { styles } from "./style";
import { dateFormat } from "../../../helpers/DateFormat";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";
const UserProfile = ({
  activeConversation,
  setIsProfile,
  activeTab,
  setActiveTab,
}) => {
  const [summary, setSummary] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchConversationMessages = async () => {
    setLoading(true);
    const activeConversationID = activeConversation?.conversationId;
    if (activeConversationID) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}chatbot/message/conversations/${activeConversationID}/summary`
        );

        setSummary(response.data.summary);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    }
  };

  return (
    <div className="_user_profile">
      <span
        onClick={() => setIsProfile(false)}
        style={{
          color: "gray",
          position: "absolute",
          top: "0",
          right: "0",
          margin: "5px 7px",
          cursor: "pointer",
          fontSize: "20px",
        }}
      >
        <RxCross2 />
      </span>
      <div style={styles.left}>
        <img
          src={activeConversation?.members[0]?.user?.profilePicture}
          alt="Avatar"
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            marginRight: "10px",
          }}
        />
        <div style={{ flexGrow: 1 }}>
          <h6 style={{ margin: "0" }}>
            {activeConversation?.members[0]?.user?.name}
          </h6>
          <small style={{ color: "#888" }}>
            {dateFormat(activeConversation?.createdAt)}
          </small>
        </div>
      </div>
      <div className="tabs">
        <button
          className={`tab ${activeTab === "details" ? "active" : ""}`}
          onClick={() => setActiveTab("details")}
        >
          Details
        </button>
        <button
          className={`tab ${activeTab === "summary" ? "active" : ""}`}
          onClick={() => {
            setActiveTab("summary");
            fetchConversationMessages();
          }}
        >
          Summary
        </button>
      </div>
      <div className="tab-content">
        {activeTab === "details" && (
          <>
            <p>
              <strong>Name: </strong>
              {activeConversation?.members[0]?.user?.name}
            </p>
            <p>
              <strong>Email: </strong>
              {activeConversation?.members[0]?.user?.email}
            </p>
            <p>
              <strong>Role: </strong>{" "}
              {activeConversation?.members[0]?.user?.role}
            </p>
          </>
        )}
        {activeTab === "summary" && (
          <div style={{ width: "100%" }}>
            <h3>Summary</h3>

            {!loading ? (
              <p> {summary} </p>
            ) : (
              <>
                <img
                  src="https://algo8.ai/wp-content/uploads/2020/09/loader.gif"
                  style={{ margin: "0 auto", width: "100%" }}
                />
                <p className="text-center">Generating summary...</p>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
