import React, { useEffect, useState } from "react";
import Chat from "./parts/Chat/Chat";

function ChatBot() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 640);
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div style={styles.container}>
      {!isSmallScreen && (
        <div style={styles.headerContainer}>
          <img
            src="/assets/images/robot.png"
            alt="Robot"
            style={styles.robotImage}
          />
          <h1 style={styles.title}>BeyondAIO ChatBOT</h1>
        </div>
      )}

      <div
        style={{
          ...styles.chatContainer,
          ...(isSmallScreen ? styles.fullScreenChat : styles.fixedChat),
        }}
      >
        <Chat />
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
    fontFamily: "Plus Jakarta Sans",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  robotImage: {
    width: "150px",
    height: "auto",
  },
  title: {
    margin: "1rem 0",
    color: "#6B7280",
    fontWeight: 700,
  },
  chatContainer: {
    position: "fixed",
    backgroundColor: "white",
    borderRadius: "15px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    width: "440px",
    height: "740px",
    transition: "width 0.3s, height 0.3s",
    zIndex: 999,
  },
  fullScreenChat: {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  fixedChat: {
    bottom: "1rem",
    right: "1rem",
  },
};

export default ChatBot;
