import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";

function CreateConfiguration() {
  const [formData, setFormData] = useState({
    businessName: "",
    businessType: "",
    location: "",
    contactInfo: "",
    operatingHours: "",
    timeZone: "",
    serviceList: [],
    serviceInput: "",
    description: "",
    websiteURL: "",
    socialMediaLinks: [{ title: "", link: "" }],
    supportHours: "",
    email: "",
    greetingMessage: "",
    aboutUsMessage: "",
    additionalProductInfo: "",
    faqs: [{ question: "", answer: "" }],
  });

  const [errors, setErrors] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  useEffect(() => {
    if (id) {
      const fetchConfiguration = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}chatbot/configuration/${id}`
          );
          setFormData(response.data.configuration);
        } catch (error) {
          console.error("Error fetching configuration:", error);
        }
      };
      fetchConfiguration();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleServiceInput = (e) => {
    if (e.key === "Enter" && formData.serviceInput.trim() !== "") {
      e.preventDefault();
      setFormData((prevData) => ({
        ...prevData,
        serviceList: [...prevData.serviceList, prevData.serviceInput],
        serviceInput: "",
      }));
    }
  };

  const removeService = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      serviceList: prevData.serviceList.filter((_, i) => i !== index),
    }));
  };

  const handleSocialMediaChange = (index, field, value) => {
    const updatedLinks = formData.socialMediaLinks.map((link, i) =>
      i === index ? { ...link, [field]: value } : link
    );
    setFormData((prevData) => ({
      ...prevData,
      socialMediaLinks: updatedLinks,
    }));
  };

  const handleFaqsChange = (index, field, value) => {
    const updatedFaqs = formData.faqs.map((faq, i) =>
      i === index ? { ...faq, [field]: value } : faq
    );
    setFormData((prevData) => ({
      ...prevData,
      faqs: updatedFaqs,
    }));
  };

  const addSocialMediaLink = () => {
    setFormData((prevData) => ({
      ...prevData,
      socialMediaLinks: [...prevData.socialMediaLinks, { title: "", link: "" }],
    }));
  };

  const addFaq = () => {
    setFormData((prevData) => ({
      ...prevData,
      faqs: [...prevData.faqs, { question: "", answer: "" }],
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach((field) => {
      if (
        field !== "socialMediaLinks" &&
        field !== "faqs" &&
        field !== "serviceList" &&
        field !== "serviceInput" &&
        !formData[field] &&
        formData[field] !== 0
      ) {
        newErrors[field] = `${field
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, (str) => str.toUpperCase())} is required`;
      }
    });

    // Validate social media links
    formData.socialMediaLinks.forEach((link, index) => {
      if (!link.title || !link.link) {
        newErrors[`socialMediaLink${index}`] = "Title and link are required";
      }
    });

    // Validate FAQs
    formData.faqs.forEach((faq, index) => {
      if (!faq.question || !faq.answer) {
        newErrors[`faq${index}`] = "Both question and answer are required";
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    console.log("submitting");
    console.log(id);
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    try {
      const apiUrl = id
        ? `${process.env.REACT_APP_BASE_URL}chatbot/configuration/update/${id}`
        : `${process.env.REACT_APP_BASE_URL}chatbot/configuration`;

      const method = id ? "put" : "post";

      const response = await axios[method](apiUrl, formData);
      console.log(response.data.message);

      navigate("/admin/configuration");
    } catch (error) {
      console.error("Error submitting configuration:", error);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive active-projects task-table">
                <div className="tbl-caption d-flex justify-content-between align-items-center">
                  <h4 className="heading mb-0">
                    {id ? "Edit Configuration" : "Create Configuration"}
                  </h4>
                </div>

                <form onSubmit={handleSubmit} className="p-3">
                  <div className="row">
                    {/* Conditionally Render the ID Field */}
                    {id && (
                      <div className="col-md-6 mb-3">
                        <label htmlFor="id" style={{ fontWeight: "bold" }}>
                          ID:
                        </label>
                        <input
                          type="text"
                          id="id"
                          name="id"
                          value={id}
                          className="form-control"
                          disabled
                        />
                      </div>
                    )}

                    {Object.keys(formData).map((field, index) =>
                      field !== "socialMediaLinks" &&
                      field !== "faqs" &&
                      field !== "serviceList" &&
                      field !== "serviceInput" ? (
                        <div key={index} className="col-md-6 mb-3">
                          <label htmlFor={field} style={{ fontWeight: "bold" }}>
                            {field
                              .replace(/([A-Z])/g, " $1")
                              .replace(/^./, (str) => str.toUpperCase())}
                            :
                          </label>
                          <input
                            type="text"
                            id={field}
                            name={field}
                            value={formData[field]}
                            onChange={handleChange}
                            className="form-control"
                            placeholder={`Enter ${field
                              .replace(/([A-Z])/g, " $1")
                              .toLowerCase()}`}
                          />
                          {errors[field] && (
                            <div className="text-danger">{errors[field]}</div>
                          )}
                        </div>
                      ) : null
                    )}

                    {/* Service List Section */}
                    <div className="col-md-6 mb-3">
                      <label
                        htmlFor="serviceList"
                        style={{ fontWeight: "bold" }}
                      >
                        Service List:
                      </label>
                      <input
                        type="text"
                        name="serviceInput"
                        value={formData.serviceInput}
                        onChange={handleChange}
                        onKeyDown={handleServiceInput}
                        className="form-control"
                        placeholder="Type a service and press Enter"
                      />
                      <div className="mt-2">
                        {formData.serviceList.map((service, index) => (
                          <span
                            key={index}
                            className="badge bg-secondary me-2"
                            style={{
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            {service}{" "}
                            <button
                              type="button"
                              className="btn-close ms-2"
                              onClick={() => removeService(index)}
                              style={{ fontSize: "0.75rem" }}
                            ></button>
                          </span>
                        ))}
                      </div>
                    </div>

                    {/* Social Media Links Section */}
                    <div className="col-12">
                      <h5>Social Media Links</h5>
                      {formData.socialMediaLinks.map((link, index) => (
                        <div key={index} className="row mb-3">
                          <div className="col-md-6">
                            <input
                              type="text"
                              placeholder="Title"
                              value={link.title}
                              onChange={(e) =>
                                handleSocialMediaChange(
                                  index,
                                  "title",
                                  e.target.value
                                )
                              }
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-6">
                            <input
                              type="text"
                              placeholder="Link"
                              value={link.link}
                              onChange={(e) =>
                                handleSocialMediaChange(
                                  index,
                                  "link",
                                  e.target.value
                                )
                              }
                              className="form-control"
                            />
                          </div>
                        </div>
                      ))}
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={addSocialMediaLink}
                      >
                        Add Social Media Link
                      </button>
                    </div>

                    {/* FAQ Section */}
                    <div className="col-12 mt-3">
                      <h5>FAQs</h5>
                      {formData.faqs.map((faq, index) => (
                        <div key={index} className="row mb-3">
                          <div className="col-md-6">
                            <input
                              type="text"
                              placeholder="Question"
                              value={faq.question}
                              onChange={(e) =>
                                handleFaqsChange(
                                  index,
                                  "question",
                                  e.target.value
                                )
                              }
                              className="form-control"
                            />
                          </div>
                          <div className="col-md-6">
                            <input
                              type="text"
                              placeholder="Answer"
                              value={faq.answer}
                              onChange={(e) =>
                                handleFaqsChange(
                                  index,
                                  "answer",
                                  e.target.value
                                )
                              }
                              className="form-control"
                            />
                          </div>
                        </div>
                      ))}
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={addFaq}
                      >
                        Add FAQ
                      </button>
                    </div>
                  </div>

                  <button type="submit" className="btn btn-primary mt-3">
                    {id ? "Update Configuration" : "Create Configuration"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateConfiguration;
