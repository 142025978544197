import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";
import axios from "axios";
import { styles } from "./MessageStyle";

function Appointment({ msg }) {
  const [showModal, setShowModal] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isAppointed, setIsAppointed] = useState(true);
  const [error, setError] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDateTime(date);
  };

  const handleConfirm = async () => {
    if (!selectedDateTime) {
      setError("Please select a date and time.");
      return;
    }

    const chatUser = JSON.parse(localStorage.getItem("chatUser"));
    const formattedDate = selectedDateTime.toISOString().split("T")[0];
    const formattedTime = selectedDateTime.toTimeString().split(" ")[0];

    const payload = {
      chatUserId: chatUser?.user?.userId,
      date: formattedDate,
      time: formattedTime,
      email: chatUser?.user?.email,
    };

    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}chat-appointment`,
        payload
      );

      if (response.status !== 201) {
        throw new Error(
          "Failed to schedule the appointment. Please try again."
        );
      }

      Swal.fire({
        icon: "success",
        title: "Appointment Scheduled",
        text: response.data.message,
        confirmButtonColor: "#3085d6",
      });
      setIsAppointed(false);
      setShowModal(false);
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text:
          err.response?.data?.message || err.message || "An error occurred.",
        confirmButtonColor: "#d33",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <p
      style={{
        ...styles.message,
        paddingBottom: "40px",
        backgroundColor: "#f3f3f3",
      }}
    >
      {msg.message}
      <div
        style={{
          padding: "3px",
          position: "absolute",
          bottom: "3px",
          right: "12px",
        }}
      >
        {isAppointed ? (
          <>
            <button
              style={styles.buttonYes}
              onClick={() => setShowModal(true)}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#197bb3")}
              onMouseLeave={(e) =>
                (e.target.style.backgroundColor = "dodgerblue")
              }
            >
              Get Appointment
            </button>
          </>
        ) : (
          <span style={{ color: "gray" }}>Scheduled Appointment</span>
        )}
      </div>

      {showModal && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
              textAlign: "center",
            }}
          >
            <h2>Select Date and Time</h2>
            <DatePicker
              selected={selectedDateTime}
              onChange={handleDateChange}
              showTimeSelect
              dateFormat="yyyy/MM/dd hh:mm aa"
              timeFormat="HH:mm"
              timeIntervals={15}
              inline
            />
            {error && <p style={{ color: "red" }}>{error}</p>}
            <div style={{ marginTop: "20px" }}>
              <button
                style={{ marginRight: "10px", ...styles.button }}
                onClick={() => setShowModal(false)}
              >
                Cancel
              </button>
              <button
                style={{ ...styles.buttonYes }}
                onClick={handleConfirm}
                disabled={loading}
              >
                {loading ? "Saving..." : "Confirm"}
              </button>
            </div>
          </div>
        </div>
      )}
    </p>
  );
}

export default Appointment;
