import React, { useState, useEffect } from "react";
import Header from "../../parts/Header/Header";
import { FaArrowRight } from "react-icons/fa";
import axios from "axios";
import { io } from "socket.io-client";
// const socket = io("http://localhost:8080");
const socket = io("https://api.beyondaio.com");

function AllChats({ prevPage }) {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [conversations, setConversations] = useState([]);

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const { user } = JSON.parse(localStorage.getItem("chatUser"));
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}chatbot/conversation/user/${user?.userId}`
        );
        setConversations(response.data.conversations);
      } catch (error) {
        console.error("Error fetching conversations:", error);
      }
    };

    fetchConversations();
  }, []);

  const handleMouseEnter = (index) => setHoveredIndex(index);
  const handleMouseLeave = () => setHoveredIndex(null);

  const handleSubmit = async () => {
    try {
      const { user } = JSON.parse(localStorage.getItem("chatUser"));
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}chatbot/conversation/direct-chat`,
        {
          userId: user?.userId,
          role: user?.role,
        }
      );
      const addDirectChat = response.data;

      const chatUser = JSON.parse(localStorage.getItem("chatUser"));

      socket.emit("adminJoin");
      socket.emit("newChat", { data: chatUser });

      localStorage.setItem(
        "conversationID",
        addDirectChat.conversation.conversationId
      );
      localStorage.setItem(
        "chatUser",
        JSON.stringify({
          conversation: addDirectChat.conversation,
          newSession: addDirectChat.newSession,
          user: chatUser?.user,
        })
      );

      prevPage();
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.headerWrapper}>
        <Header bg={true} />
      </div>
      <h1 className="text-center" style={{ color: "#aaa" }}>
        Messages
      </h1>
      <div style={styles.allChat}>
        {conversations.map((conversation, index) => {
          const user = conversation.members.find(
            (member) => member?.user?.role === "user"
          )?.user;
          const bot = conversation?.members?.find(
            (member) => member?.user?.role === "bot"
          )?.user;

          return (
            <div
              key={conversation.conversationId}
              style={{
                ...styles.singleChat,
                backgroundColor:
                  hoveredIndex === index ? "#f0f0f0" : "transparent",
              }}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              onClick={() => {
                prevPage();
                localStorage.setItem(
                  "conversationID",
                  conversation.conversationId
                );
              }}
            >
              <img
                src={user?.profilePicture}
                alt="avatar"
                width={60}
                height={60}
                style={styles.avatar}
              />
              <div style={styles.chatInfo}>
                <h2 style={styles.chatName}>{user?.name}</h2>
                <p style={styles.lastMessage}>
                  {bot?.name}: Hey, how's it going?
                </p>
              </div>
              <div style={styles.chatMeta}>
                <span style={styles.timestamp}>
                  {new Date(conversation.createdAt).toLocaleTimeString()}
                </span>
                <FaArrowRight style={styles.arrowIcon} />
              </div>
            </div>
          );
        })}
      </div>

      <div style={styles.addButtonWrapper}>
        <button
          style={{
            ...styles.addButton,
            backgroundColor: isHovered ? "#28A17C" : "#33B393",
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={handleSubmit}
        >
          Start New Chat
        </button>
      </div>
    </div>
  );
}

export default AllChats;

export const styles = {
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    backgroundColor: "#fff",
    borderRadius: "8px",
    paddingBottom: "0px",
    fontFamily: "Outfit",
  },
  headerWrapper: {
    width: "100%",
    padding: "16px 14px 0px 14px",
  },
  allChat: {
    marginTop: "20px",
    padding: "10px",
    overflowY: "auto",
    flexGrow: 1,
  },
  singleChat: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    marginBottom: "5px",
    cursor: "pointer",
    transition: "background-color 0.3s, transform 0.1s",
    borderBottom: ".2px solid #ccc",
  },
  avatar: {
    borderRadius: "50%",
    marginRight: "15px",
    border: "1px solid #ccc",
  },
  chatInfo: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  chatName: {
    margin: 0,
    fontWeight: "bold",
    fontSize: "16px",
  },
  lastMessage: {
    margin: 0,
    color: "#888",
    fontSize: "14px",
  },
  chatMeta: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginLeft: "10px",
  },
  timestamp: {
    fontSize: "12px",
    color: "#aaa",
    marginRight: "10px",
  },
  arrowIcon: {
    fontSize: "16px",
    color: "#888",
    transition: "transform 0.1s",
  },
  addButtonWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
    marginBottom: "20px",
  },
  addButton: {
    padding: "10px 20px",
    backgroundColor: "#33B393",
    color: "#fff",
    fontSize: "16px",
    fontWeight: "bold",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
};
